// custom fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


// variables the css-way
// usage: var(--css-name)
:root {
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

// variables the scss way
$color-bg: #1f1f38;
$color-bg-variant: #2c2c6c;
$color-primary: #4db5ff;
$color-primary-variant: rgba(77, 181, 255, 0.4);
$color-white: #fff;
$color-light: rgba(255, 255, 255, 0.6);

$transition: all 400ms ease;

$container-width-lg: 75%;
$container-width-md: 86%;
$container-width-sm: 90%;


// presets
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}


// smooth scroll
html {
    scroll-behavior: smooth;
}

// hide scrollbar
::-webkit-scrollbar {
    display: none;
}

// body font
body {
    font-family: 'Poppins', sans-serif;
    background: $color-bg; //css way: var(--color-bg)
    color: $color-white;
    line-height: 1.7;
    background-image: url(../src/assets/bg-texture.png)
}


// general styles

.container {
    width: $container-width-lg;
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500px;
}

h1 {
    font-size: 2.5rem;
}


section {
    margin-top: 8rem;
    // height: 100vh;
}

section > h2, section > h5 {
    text-align: center;
    color: $color-light;
}

section > h2 {
    color: $color-primary;
    margin-bottom: 3rem;
}

.text-light {
    color: $color-light;
}

a {
    color: $color-primary;
    transition: $transition;
}

a:hover {
    color: $color-white;
}

.btn {
    width: max-content;
    display: inline-block;
    color: $color-primary;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid $color-primary;
}

.btn:hover {
    background: $color-white;
    color: $color-bg;
    border-color: transparent;
}

.btn-primary {
    background: $color-primary;
    color: $color-bg;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

// media queries (Medium Devices: Tablets)
@media screen and (max-width: 1024px) {
    .container {
        width: $container-width-md;
    }

    section {
        margin-top: 6rem;
    }
}

// media queries (Small Devices: Tablets)
@media screen and (max-width: 600px) {
    .container {
        width: $container-width-sm;
    }

    section > h2 {
        margin-top: 2rem;
    }
}


